import React, { useState, useEffect } from "react";
import axios from "axios";
import Logo from "../assets/icon/GUARDPOINTBIG.svg";

const ThanksPage = () => {
  const [countGuards, setCountGuards] = useState('0');
  const [countClients, setCountClients] = useState('0');

  useEffect(() => {
    const fetchСountGuards = async () => {
      try {
        const responseGuards = await axios.get(
          "https://guardpoint.nl:8090/api/v1/user/countGuards"
        );
        const responseClients = await axios.get(
          "https://guardpoint.nl:8090/api/v1/user/countClients"
        );
        setCountGuards(responseGuards.data);
        setCountClients(responseClients.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchСountGuards();
  }, []);

  return (
      <>
    <section className="thanks">
      <div className="thanks__wrapper">
        <img src={Logo} alt="Logo" />
        <h2 className="thanks__title">Binnenkort beschikbaar</h2>
        <div className="thanks__copiright">Bedankt voor uw registratie</div>
        <div className="intro__mini-wrapper intro__mini-wrapper-two intro__mini-wrapper-thanks">
          <ul className="intro__number-list">
            <li className="intro__number-item intro__number-item--one">
              <h3 className="intro__number-title">Geregistreerde klanten</h3>
              <div className="intro__number">{countClients}</div>
            </li>
            <li className="intro__number-item">
              <h3 className="intro__number-title">
              Beveiligers geregistreerd
              </h3>
              <div className="intro__number">{countGuards}</div>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <div className="thanks__wrapper-policy">Privacybeleid</div>
    </>
  );
};

export default ThanksPage;
